<template>
	<div>
		<!-- 面包屑 -->
		<Breadcrumb></Breadcrumb>
		<el-card class="mt-2">
		<div class="w-100 h-auto flex flex-column">
			<el-form ref="form" :model="form" :rules="rules" label-width="80px" size="small">
				<el-form-item label="活动名称" prop="title">
				  <el-input v-model="form.title" placeholder="请输入活动名称" type="text" clearable />
				</el-form-item>
				<el-form-item label="主办单位" prop="zbdw">
				  <el-input v-model="form.zbdw" placeholder="请输入主办单位" type="text" clearable />
				</el-form-item>
				<el-form-item label="活动地址" prop="address">
				  <el-input v-model="form.address" placeholder="请输入活动地址" type="text" clearable />
				</el-form-item>
				<el-form-item  label="推荐">
				   <el-radio-group v-model="form.is_rec">
					<el-radio :label="1">是</el-radio>
					<el-radio :label="2">否</el-radio>
				   </el-radio-group>
				 </el-form-item>
				<el-form-item label="活动时间" prop="time">
				  <el-date-picker
					 v-model="form.time"
					 @blur="datePickerTime"
					 value-format="yyyy-MM-dd"
					 type="daterange"
					 range-separator="至"
					 start-placeholder="开始日期"
					 end-placeholder="结束日期">
				  </el-date-picker>
				</el-form-item>
				<el-form-item label="封面" prop="url">
					  <div class="border rounded flex-all mr-3" style="width: 100px;height: 100px;line-height: 100px;cursor: pointer;"
					  @click="chooseImage"
					  >
						<img :src="form.url" class="w-100 h-100" v-if="form.cover">
						<i class="el-icon-plus text-muted" style="font-size: 30px;" v-else></i>
					  </div>
				</el-form-item>
				<el-form-item label="附件" prop="path">
					<el-upload
					  class="upload-border"
					  action="/manage/file/upload"
					  :show-file-list="false"
					  :on-success="uploadSuccess"
					  :before-upload="beforeUpload">
					  <div class="flex align-center flex-wrap overflow-hidden" style="height: 100px;" v-if="file.id">
						<span style="width: 100px;word-wrap:break-word;word-break:break-all;overflow: hidden;">{{file.name || ''}}</span>
					  </div>
					  <i class="el-icon-plus text-muted" style="font-size: 30px;" v-else></i>
					</el-upload>
					<small class="d-block text-secondary mt-1 text-danger" style="line-height: 20px;">word格式请选择上传后缀.docx</small>
				</el-form-item>
				<el-form-item label="活动内容">
				   <tinymce ref="editor" :key="tinymceFlag" v-model="form.content" />
				</el-form-item>
				<el-form-item  label="状态">
				   <el-radio-group v-model="form.status">
					<el-radio :label="1">使用</el-radio>
					<el-radio :label="2">停用</el-radio>
				   </el-radio-group>
				 </el-form-item>
				<el-form-item label="排序" prop="sort">
				  <el-input v-model="form.sort" placeholder="请输入排序" type="text" clearable />
				</el-form-item>
			</el-form>
			<div class="w-100 flex-all mt-2">
			  <el-button size="medium" @click="cancel">取 消</el-button>
			  <el-button type="primary" size="medium" @click="handleSubmit" style="margin-left: 30px;">确 定</el-button>
			</div>
		</div>
		</el-card>
	</div>
</template>

<script>
import GlobalTips from "@/components/GlobalTips";
import common from "@/common/mixins/common.js"
import Breadcrumb from '@/components/Breadcrumb';
import tinymce from "@/components/common/tinymce.vue"
export default {
	inject:['app'],
	mixins:[common],
	components: {
		GlobalTips,
		Breadcrumb,
		tinymce
	},
	data() {
		return {
			preUrl: 'activity',
			autoRequest: false,
			queryForm: {
				page: 1,
				limit: 10,
				keyword: '',
			},
			form:{
				title:'',
				address:'',
				zbdw:'',
				time:[],
				content:'',
				sort:100,
				status:1,
				is_rec:2,
				cover:'',
				url:'',
				file_id:'',
			},
			rules:{
				title: [
				  { required: true, message: '请输入活动名称', trigger: 'blur' }
				],
			},
			tinymceFlag:0,
			types:[],
			images:[],
			file:{},
		}
	},
	mounted() {
		if(this.$route.query.article){
			 this.form = this.$route.query.article
			 this.file = this.$route.query.article.file ? this.$route.query.article.file : {}
			 if(this.$route.query.article.stime != '' && this.$route.query.article.etime != ''){
				 this.form.time = []
				 this.form.time[0] = this.$route.query.article.stime
				 this.form.time[1] = this.$route.query.article.etime
			 }
		}
	},
	methods:{
		reset() {
		  this.form = {
			  title:'',
			  zbdw:'',
			  address:'',
			  time:[],
			  content:'',
			  sort:100,
			  status:1,
			  is_rec:2,
			  cover:'',
			  url:'',
			  file_id:'',
		  }
		  this.resetForm('form')
		},
		chooseImage(){
			this.app.chooseImage((res)=>{
			  this.form.cover = res[0].id
			  this.form.url = res[0].url
			})
		},
		uploadSuccess(e){
		   if(e.status){
			 this.file = e.data
			 this.form.file_id = e.data.id
		   }
		},
		beforeUpload(){},
		handleSubmit() {
		  this.$refs.form.validate(valid => {
		    if (valid) {
		      if (this.form.sort) {
		        this.form.sort = parseInt(this.form.sort)
		      }
		      if (this.form.id === undefined) {
		        this.axios.post('/manage/'+this.preUrl + '/add', this.form).then(res => {
		          if (res.status) {
		            this.$message.success('编辑成功')
		            this.$router.go(-1);
		          } else {
		            this.$message.error(res.msg)
		          }
		        })
		      } else {
		        this.axios.put('/manage/'+this.preUrl + '/edit/' + this.form.id, this.form).then(res => {
		          if (res.status) {
		            this.$message.success('修改成功')
		            this.$router.go(-1);
		          } else {
		            this.$message.error(res.msg)
		          }
		        })
		      }
		    }
		  })
		},
		cancel(){
			this.$router.go(-1);
		},
		datePickerTime(e){
			this.$forceUpdate();
		},
	},
}
</script>

<style>
</style>